<template>
  <div>
    <h1>Esta página todavía no está disponible.</h1>
  </div>
</template>

<script>
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";

async function checkToken(token) {
  await axios
    .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_CHECKTOKEN, {
      headers: {
        Authorization: token,
      },
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          window.location.href = "#/pages/login";
        }
        if (error.response.status == 404) {
          window.location = "#/404";
        }
        if (error.response.status == 500) {
          window.location = "#/500";
        }
      }
    });
}

export default {
  name: "Inicio",
  created() {
    checkToken(localStorage.token);
  },
};
</script>
